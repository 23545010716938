import $ from "cash-dom";
import UIkit from 'uikit';

import {executeAction} from "./utils.js";
import TomSelect from "./tom-select.js";

$.fn.postFormAsAjax = async function() {
    const formData = new FormData(this[0]);
    const response = await fetch(this.attr("action"), {
      method: this.attr("method"),
      cache: 'no-cache',
      referrerPolicy: 'no-referrer',
      body: formData
    });
    return response.json();
  }

$(function () {
    // JSON FORMS
    $("body").on("submit", ".json-form", function (evt) {
        evt.preventDefault();
        $(this).postFormAsAjax().then(message => {
            if(message) executeAction(message);
        }).catch(error => console.log("Error posting form " + error));
    });

    // File upload
    $(".file-input-wrapper").on("change", "input[type='file']", function(event){
        if(event.target.files.length > 0){
          // TODO: Gestire il caso multiple prima o poi
          const file = event.target.files[0]
          const preview_container = $(this).closest(".upload-file").find(".preview");
          let preview = "";
          if (file.type.startsWith("image/")){
            preview = $("<img />");
            preview.attr("src", URL.createObjectURL(file));
          }
          else if (file.type.startsWith("video/")){
            preview = $("<video />");
            preview.attr("src", URL.createObjectURL(file));
          }
          else {
            const splittedType = file.type.split("/");
            let type = splittedType[0];
            if (splittedType.length > 1) type = splittedType[splittedType.length - 1]
            preview = $(`<a>${file.name} ( ${type})</a>`);
            preview.attr("href", URL.createObjectURL(file)).attr("target", "_blank");
          }

          preview_container.css("display", "block");
          // if (!$(this).attr("multiple"))
          preview_container.empty();
          preview_container.append(preview);
        }
    });

    // Ordered multiselects
    $(".tom-select").each( function(){
      const $input = $(this);
      const options_id = "#" + $input.data("select-options-id");
      const options = JSON.parse($(options_id).text());
      const select = new TomSelect($input[0], {
          plugins: ["remove_button", ],
          options: options,

      });
      const control = wrapper.find(".ts-control");
      const sortable = UIkit.sortable(control[0], { handle:"div" });
      UIkit.util.on(sortable.$el, 'moved', function (event, sortable, element) {
          let selected = Array();
          control.children("div").each( function (){
              selected.push($(this).data("value"));
          });
          $input.val(selected.join(","));
      });
  });
})
