import $ from "cash-dom";
import UIkit from 'uikit';
import { initChat, messaggioChatAperta, messaggioChatChiusa } from "./chat.js";
import { getCookie } from "./cookies.js";
import { executeAction } from "./utils.js";

function getUserSocket() {
    // Gets or creates the user socket
    if (window.API.websocket) {
        if (window.API.websocket.readyState === WebSocket.OPEN) {
            return window.API.websocket
        }
    }
    console.log('Creating new socket for user');
    const protocol = window.location.protocol.replace('http', 'ws');
    const seed = Math.random().toString(36).substring(2, 15);
    let ws = new WebSocket(protocol + '//' + window.location.host + '/ws/user/' + seed + '/');

    ws.onmessage = function (e) {
        const message = JSON.parse(e.data);
        const room = $('#chat').data("room");
        const site = $('#chat').data("site");
        console.log("message received");
        if (message.type == "chat.message") {
            if ($('#chat').length > 0 && room === message.room && site === message.site) {
                const time = new Date(message.time);
                messaggioChatAperta(message, time);
            }
            else {
                messaggioChatChiusa(message);
            }
        }
        else if (message.type == "chat.read") {
            $("#chat_message_" + message.id + " .unreaded").removeClass("unreaded").addClass("readed");
        }
        else executeAction(message, message.type)
    };

    ws.onclose = function (e) {
        console.log('Socket is closed. Reconnect will be attempted in 1 second.', e.reason);
        setTimeout(function () {
            getUserSocket();
        }, 3000);
    };

    ws.onerror = function (e) {
        console.error('Socket encountered error: ', e.message, 'Closing socket');
        ws.close();
    };
    window.API.websocket = ws;
    return ws;
}

function openModal(url, callback=false) {
    fetch(url)
    .then(response => {
        const contentType = response.headers.get("content-type");
        if (contentType == "application/json"){
            response.json()
            .then(data => {
                executeAction(data);
                if(callback){
                    callback();
                }
            })
            .catch(error => console.log("Error parsing json data from url " + url + " " + error));
        }
        else {
            response.text()
            .then(html => {
                $("#modal-container").html(html);
                if(callback){
                    callback();
                }
            }).catch(error => console.log("Error fetching modal from url " + url + " " + error));
        }
    });
    const modal = UIkit.modal($("#modal-container")[0], { center: true });
    modal.show();
}


function trackAnalyticsEvent(category, action, name) {
    _paq.push(['trackEvent', category, action, name]);
}

function openPage(path, callback=false) {
    trackAnalyticsEvent('page', 'click', path);
    openModal(path, callback);
}

function openEvent(path) {
    trackAnalyticsEvent('event', 'click', path);
    openModal(path);
}

function openVideo(path) {
    trackAnalyticsEvent('video', 'click', path);
    openModal(path);
}

function openAudio(path) {
    trackAnalyticsEvent('audio', 'click', path);
    openModal(path);
}

function openImage(path) {
    trackAnalyticsEvent('image', 'click', path);
    openModal(path);
}

function openPdf(path) {
    trackAnalyticsEvent('pdf', 'click', path);
    openModal(path);
}

function openLink(path) {
    trackAnalyticsEvent('link', 'click', path);
    window.open(path, '_blank').focus();
}

function openChat(path) {
    trackAnalyticsEvent('chat', 'click', path);
    openModal(path, function(){
        initChat(JSON.parse($("#chat-messages").text()))
    });
}

function downloadFile(path){
    trackAnalyticsEvent('download', 'click', path);
    window.location.replace(path);
}

function saveAvatar(object) {
    const url = $('#unityContainer').data("avatar-url");
    const body = { avatar: object, csrfmiddlewaretoken: getCookie('csrftoken') };
    fetch(url, {
      method: "POST",
      cache: 'no-cache',
      headers: {'Content-Type': 'application/json'},
      referrerPolicy: 'no-referrer',
      body: body
    })
    .then(response => response.json())
    .then(data => window.unityInstance.SendMessage("Networking", "SetAvatar", JSON.stringify(data)))
    .catch(error => console.log("Error fetching modal from url " + url + " " + error));
  }

function getAvatar() {
    const url = $('#unityContainer').data("avatar-url");
    fetch(url)
    .then(response => response.json())
    .then(data => {
        window.unityInstance.SendMessage("Networking", "GetAvatar", JSON.stringify(data));
    }).catch(error => console.log("Error fetching avatar " + url + " " + error));
}

window.API = {
    getUserSocket: getUserSocket,
    downloadFile: downloadFile,
    openModal: openModal,
    openChat: openChat,
    openLink: openLink,
    openPage: openPage,
    trackAnalyticsEvent: trackAnalyticsEvent,
    openVideo: openVideo,
    openAudio, openAudio,
    openEvent: openEvent,
    openImage: openImage,
    openPdf: openPdf,
    saveAvatar: saveAvatar,
    getAvatar: getAvatar,
}
